import React, {useState} from "react"
import {Container,Table,Row,Col,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import SEO from "../../components/seo"

import Comparables from  "../../components/modules/comparables";  

import {config} from '../../components/Common/constant';

import GoogleSingleMap from "../../components/maps/google/comparables-map"

import { useContainerContext } from "../../store/ContainerContext";



function PresentationPage(){

    const context = useContainerContext();
    const { popup_property_flag, property_data } = context.state;

    return <>
      <SEO title="Your Property Market" description="Your Property Market" />

      <div className="pre-wrapper">
        
        <Header />

        <div className="section-wrapper custom_present_inner pt-0">
          {property_data && <Comparables property_data={property_data} /> }
        </div>

        <NavLink tab_name="Comparables" prevLink={config.custom_teams} nextLink={property_data && property_data.product_specifications ? config.custom_marketing_campaign : config.custom_fees} />

    </div>
    </>
}

export default PresentationPage
